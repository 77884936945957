// Navbar.jsx
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../assets/logo-square.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  // Close mobile menu when clicking outside
  const closeNav = () => setIsOpen(false);

  // Change navbar style on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 90) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
      <>
        {/* Backdrop overlay when mobile menu is open */}
        {isOpen && (
            <div
                className="fixed inset-0 bg-slate-900 bg-opacity-50 z-30 lg:hidden transition-opacity duration-300"
                onClick={closeNav}
            />
        )}

        {/* Main navbar */}
        <header className={`w-full z-40 transition-all duration-300 ${scrolled ? "shadow-md bg-secondaryColor" : ""}`}>
          <div className="container mx-auto px-4">
            <nav className="flex justify-between items-center py-4">
              {/* Logo - visible on desktop */}
              <Link to="/" className="hidden lg:block">
                <img src={logo} alt="Safe Whisper" className="h-14 w-auto" />
              </Link>

              {/* Desktop menu */}
              <div className="hidden lg:flex lg:items-center lg:space-x-8">
                <Link to="/" className="text-primaryColor font-medium hover:text-sky-400 transition-colors">
                  Home
                </Link>
                <Link to="/features" className="text-primaryColor font-medium hover:text-sky-400 transition-colors">
                  Features
                </Link>
                <Link to="/contact" className="text-primaryColor font-medium hover:text-sky-400 transition-colors">
                  Contact
                </Link>
                <Link to="/about" className="text-primaryColor font-medium hover:text-sky-400 transition-colors">
                  About Us
                </Link>
              </div>
            </nav>
          </div>
        </header>

        {/* Mobile menu panel */}
        <div
            className={`fixed top-0 right-0 h-full w-4/5 max-w-xs bg-secondaryColor z-40 shadow-2xl transition-transform duration-300 transform ${
                isOpen ? "translate-x-0" : "translate-x-full"
            } lg:hidden`}
        >
          <div className="flex flex-col h-full pt-16 px-6">
            <Link to="/" className="mb-8 mx-auto" onClick={closeNav}>
              <img src={logo} alt="Safe Whisper" className="h-16 w-auto" />
            </Link>

            <div className="space-y-6 flex flex-col items-center">
              <Link to="/" className="text-primaryColor text-xl font-medium" onClick={closeNav}>
                Home
              </Link>
              <Link to="/features" className="text-primaryColor text-xl font-medium" onClick={closeNav}>
                Features
              </Link>
              <Link to="/contact" className="text-primaryColor text-xl font-medium" onClick={closeNav}>
                Contact
              </Link>
              <Link to="/about" className="text-primaryColor text-xl font-medium" onClick={closeNav}>
                About Us
              </Link>
            </div>
          </div>
        </div>

        {/* Mobile menu toggle button - Always in the same position */}
        <button
            className="lg:hidden fixed bottom-8 right-8 z-50 bg-slate-700 text-white p-3 rounded-full shadow-lg"
            onClick={() => setIsOpen(!isOpen)}
            aria-label={isOpen ? "Close menu" : "Open menu"}
        >
          {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>
      </>
  );
};

export default Navbar;